import { createActions, createReducer } from "reduxsauce";
import axios from "axios";
const { Types, Creators } = createActions({
  requestAction: [],
  errorAction: ["error"],
  successfulCompanyRegistration: ["payload"],
  successfulSignIn: ["payload"],
  successfulUpdateCompanyInfo: ["payload"],
  successfulGetOrdersHistory: ["payload"],
  successfulLogOut: [],
});

export const AuthActionCreators = Creators;

const initialState = {
  loading: false,
  companyData: {},
  error: "",
  isAuthenticated: false,
  ordersHistory: [],
};

const requestAction = (state = initialState) => ({
  ...state,
  loading: true,
  error: "",
});

const successfulCompanyRegistration = (state = initialState, action) => {
  const { message } = action.payload.result;
  const accessToken = message;
  localStorage.setItem("jwt_access_token", accessToken);
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  return {
    ...state,
    loading: false,
    message: "Usuario registrado con éxito",
    isAuthenticated: true,
  };
};

const successfulSignIn = (state = initialState, action) => {
  const { user, token } = action.payload.result;
  localStorage.setItem("jwt_access_token", token);
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  return {
    ...state,
    loading: false,
    companyData: { ...user },
    message: "Bienvenido de vuelta!",
    isAuthenticated: true,
  };
};

const successfulUpdateCompanyInfo = (state = initialState, action) => {
  const data  = action.payload.result;
  return {
    ...state,
    loading: false,
    companyData: { ...data },
  };
};

const successfulGetOrdersHistory = (state = initialState, action) => {
  const {orders}  = action.payload.result;
  
  return {
    ...state,
    loading: false,
    ordersHistory: orders,
  };
};


const errorAction = (state = initialState, action) => ({
  ...state,
  loading: false,
  error: action.error,
});

const successfulLogOut = (state = initialState) => {
  localStorage.removeItem("jwt_access_token");
  return {
    ...state,
    loading: false,
    companyData: {},
    isAuthenticated: false,
  };
};


export default createReducer(initialState, {
  [Types.REQUEST_ACTION]: requestAction,
  [Types.SUCCESSFUL_COMPANY_REGISTRATION]: successfulCompanyRegistration,
  [Types.SUCCESSFUL_SIGN_IN]: successfulSignIn,
  [Types.SUCCESSFUL_UPDATE_COMPANY_INFO]: successfulUpdateCompanyInfo,
  [Types.ERROR_ACTION]: errorAction,
  [Types.SUCCESSFUL_GET_ORDERS_HISTORY]: successfulGetOrdersHistory,
  [Types.SUCCESSFUL_LOG_OUT]: successfulLogOut,
});
