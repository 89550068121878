import { AuthActionCreators } from "../reducers/authReducer";
import {
  registerCompanyService,
  signInTokenCompanyService,
  updateCompanyInfoService,
  signInWithEmailService,
  getOrdersHistoryService,
  changePasswordService,
} from "../services/auth";

export const registerCompany = (data, toast, history) => async (dispatch) => {
  dispatch(AuthActionCreators.requestAction());
  try {
    const response = await registerCompanyService(data);

    dispatch(
      AuthActionCreators.successfulCompanyRegistration({
        result: response,
      })
    );
    toast("Usuario Registrado con exito", {
      appearance: "success",
      autoDismiss: true,
    });
    dispatch(signInWithToken(response.message, history, true));
  } catch (e) {
    toast("Por favor revisa los campos", {
      appearance: "error",
      autoDismiss: true,
    });
    dispatch(AuthActionCreators.errorAction(e));
  }
};

export const signInWithToken =
  (token, history, redirectToAccount) => async (dispatch) => {
    try {
      const response = await signInTokenCompanyService(token);

      dispatch(
        AuthActionCreators.successfulSignIn({
          result: response,
        })
      );
      if (redirectToAccount) {
        history.push("/my-account");
      }
    } catch (e) {
      
      dispatch(AuthActionCreators.errorAction(e));
    }
  };

  export const signInWithEmail =
  (data,toast, history, redirectToAccount) => async (dispatch) => {
    try {
      const response = await signInWithEmailService(data);

      dispatch(
        AuthActionCreators.successfulSignIn({
          result: response,
        })
      );
	  toast("Bienvenido de vuelta!", {
		appearance: "success",
		autoDismiss: true,
	  });
      if (redirectToAccount) {
        history.push("/my-account");
      }
    } catch (e) {
      toast("El email o contraseña son incorrectos", {
        appearance: "error",
        autoDismiss: true,
      });
      dispatch(AuthActionCreators.errorAction(e));
    }
  };


  export const changePasswordAction =
  (data,toast, history, redirectToAccount) => async (dispatch) => {
    
    try {
      const response = await changePasswordService(data);

      if (redirectToAccount) {
        history.push("/login");
      }
    } catch (e) {
      toast("El email o contraseña son incorrectos", {
        appearance: "error",
        autoDismiss: true,
      });
      dispatch(AuthActionCreators.errorAction(e));
    }
  };

export const updateCompanyInfo = (data, toast) => async (dispatch) => {
  dispatch(AuthActionCreators.requestAction());
  try {
    dispatch(
      AuthActionCreators.successfulUpdateCompanyInfo({
        result: data,
      })
    );
    await updateCompanyInfoService(data);

    toast("Usuario Actualizada con exito", {
      appearance: "success",
      autoDismiss: true,
    });
  } catch (e) {
    toast("Por favor revisa los campos", {
      appearance: "error",
      autoDismiss: true,
    });
    dispatch(AuthActionCreators.errorAction(e));
  }
};

export const getOrdersHistory = () => async (dispatch) => {
  dispatch(AuthActionCreators.requestAction());
  try {
    const orders = await getOrdersHistoryService(localStorage.getItem("jwt_access_token"));
    dispatch(
      AuthActionCreators.successfulGetOrdersHistory({
        result: orders,
      })
    );
  } catch (e) {
    dispatch(AuthActionCreators.errorAction(e));
  }
}

export const logOut = (history) => async (dispatch) => {
  try {
    dispatch(
      AuthActionCreators.successfulLogOut({
        result: {},
      })
    );
    history.push("/");
  } catch (e) {
    dispatch(AuthActionCreators.errorAction(e));
  }
}