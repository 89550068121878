import { createActions, createReducer } from "reduxsauce";

const { Types, Creators } = createActions({
  applyFilterShop: ["payload"],
  errorFilterShop: ["error"],
});

export const FilterShopActionCreators = Creators;

const initialState = {
  loading: false,
  filterValues: {
    categories: "",
    tags: "",
    type: "",
    color: "",
    size: "",
    price: "default",
  },
  error: "",
};

const applyFilterShop = (state = initialState, action) => {
  const { sortType, sortValue } = action.payload;
  let filterValues;
  if (sortType === "clean-colors") {
    filterValues = {
      ...state.filterValues,
      color: "",
    };
  } else if (sortType === "clean") {
    filterValues = {
      categories: "",
      color: "",
      size: "",
      tags: "",
      type: "",
      price: "",
    };
  } else if (sortType === "color") {
    filterValues = {
      ...state.filterValues,
      [sortType]: sortValue,
    };
  } else if (sortType === "price") {
    filterValues = {
      ...state.filterValues,
      color: "",
      [sortType]: sortValue,
    };
  }else {
    filterValues = {
      ...state.filterValues,
      categories: "",
      color: "",
      size: "",
      type: "",
      tags: "",
      [sortType]: sortValue,
    };
  }

  return {
    ...state,
    loading: false,
    filterValues,
  };
};

const errorFilterShop = (state = initialState, action) => ({
  ...state,
  loading: false,
  error: action.error,
  filterValues: {
    categories: "",
    color: "",
    size: "",
    type: "",
    tags: "",
  },
});

export default createReducer(initialState, {
  [Types.APPLY_FILTER_SHOP]: applyFilterShop,
  [Types.ERROR_FILTER_SHOP]: errorFilterShop,
});
