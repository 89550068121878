export const checkIfHasVariants = (item) =>
  (item.colors && item.colors.length > 0) ||
  (item.isGift &&
    (item.rollerOption ||
      item.pendriveOption ||
      item.penOption ||
      item.engravingOption ||
      item.imageOption));

export const checkIfVariantsMissing = (item, selectedVariants) => {
  if (checkIfHasVariants(item)) {
    return (
      (item.colors &&
        item.colors.length > 0 &&
        selectedVariants &&
        selectedVariants.colorSelected.value === "") ||
      (item.isGift &&
        selectedVariants &&
        selectedVariants.typographySelected.value === "") ||
      (item.engravingOption &&
        selectedVariants &&
        selectedVariants.engravingText === "") ||
      (item.engravingOptionPendrive &&
        selectedVariants &&
        selectedVariants.engravingPendriveText === "") ||
      (item.pendriveColorOption &&
        selectedVariants &&
        selectedVariants.colorPendriveSelected.value === "") ||
      (item.caseColorOption &&
        selectedVariants &&
        selectedVariants.colorCaseSelected.value === "")
        
    );
  }
  return checkIfHasVariants(item);
};

// (item.imageOption &&
//   selectedVariants &&
//   ((item.rollerOption &&
//     selectedVariants &&
//     selectedVariants.rollerSelected.value === "") ||
//     (item.pendriveOption &&
//       selectedVariants &&
//       selectedVariants.pendriveSelected.value === "") ||
//     (item.penOption &&
//       selectedVariants &&
//       selectedVariants.penSelected.value === "") ||
// selectedVariants.imageSelected.value === ""
